import React, { useEffect, useState } from "react"
import { observer } from "mobx-react";
import { Box, Button, CircularProgress, Divider, dividerClasses, FormControl, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Tab, Tabs, TextField, Typography } from "@mui/material";
import Layout from "../../components/shared-layout";
import useStores from "../../hooks/use-stores";
import { ApiDataListType, ApiDataResponseListLengthCheck, GetCollegeId, GetCurrentSemester, PAGE_SIZE } from "../../constants/options";
import VmButton from "../../components/shared-button";
import VmTable from "../../components/shared-table";
import VmModal from "../../components/shared-modal";
import Breadcrumb from "../../components/shared-breadcrumb";
import { t } from "i18next";
import ContentLayout from "../../components/shared-content-layout";
import { INLINE_SELECTED_OPTION, INLINE_SELECTED_OPTION_MINI, INLINE_SELECT_OPTION, INLINE_SELECT_OPTION_MINI, ITEM_PERFECT_INLINED, TD_FIRST, TD_LAST, TD_NORMAL, THEME_HIGHLIGHT } from "../../constants/style";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import TabPanel from "../../components/shared-tab-panel";
import { constructImgUrl } from "../../constants/settings";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';

function TabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TeacherProfilePage = observer(({ location }) => {
  const { rootStore, groupStore, teacherStore, calendarStore } = useStores();
  const [teacherId, setTeacherId] = useState<string>(location.state && location.state.teacherId ? location.state.teacherId : "0");
  const [currentTeacher, setCurrentTeacher] = useState<any>();
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [tabValue, setTabValue] = useState<number>(1);
  const [modalTabValue, setModalTabValue] = useState<number>(0);
  const [currentVisit, setCurrentVisit] = useState<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [studentId, setStudentId] = useState<string>("0");
  const [yearId, setYearId] = useState<string>("0");
  const [semesterId, setSemesterId] = useState<string>("0");
  const [currentOrg, setCurrentOrg] = useState<number>(0);
  const [currentSubjectClass, setCurrentSubjectClass] = useState<number>(0);
  const [timeKeyword, setTimeKeyword] = useState<string>("");
  const [orgSubjectClassInfo, setOrgSubjectClassInfo] = useState<any>({ orgName: "", classCode: "" });
  const [filteredMode, setFilteredMode] = useState<boolean>(false);
  const [searchVisitName, setSearchVisitName] = useState<string>("");

  useEffect(() => {
    // get current semester id and year id
    if (ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) == 0 ||
      ApiDataResponseListLengthCheck(ApiDataListType.CALENDAR) != calendarStore.calendarSettingList.data.length) {
      calendarStore.getCalendarSettingList(0, 0).then((res) => onProcessCalendarSetting(res));
    } else {
      onProcessCalendarSetting(calendarStore.calendarSettingList);
    }
    if (ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 ||
      teacherStore.teacherProfileList.data.length != ApiDataResponseListLengthCheck(ApiDataListType.TEACHER)) {
      teacherStore.getTeacherProfileList(0, 0)
        .then(() => {
          if (location.state && location.state.teacherId) onSelectTeacher(location.state.teacherId);
        });
    } else {
      if (location.state && location.state.teacherId) onSelectTeacher(location.state.teacherId);
    }
  }, []);

  const onChangeTeacher = (event: SelectChangeEvent) => {
    let id = event.target.value;
    onSelectTeacher(id);
  };
  const onChangeStudent = (event: SelectChangeEvent) => {
    setStudentId(event.target.value);
  };
  const onChangeYear = (event: SelectChangeEvent) => {
    let id = event.target.value as string;
    setYearId(id);
    if (+id != 0 && calendarStore.calendarSettingList.data
      .find(y => y.id == +id).schoolSemesters.length > 0) {
      setSemesterId(calendarStore.calendarSettingList.data
        .find(y => y.id == +id).schoolSemesters[0].id)
    } else {
      setSemesterId("0");
    }
  };
  const onChangeSemester = (event: SelectChangeEvent) => {
    setSemesterId(event.target.value as string);
  };

  const onSelectTeacher = (id: string) => {
    if (id === "0") setCurrentTeacher(null);
    else {
      setCurrentTeacher(teacherStore.teacherProfileList.data.find(t => t.id == +id));
      teacherStore.getVisitListByStaffId(+id, false, PAGE_SIZE, 0);
      onProcessStudentListGroup(+id, +semesterId == 0 ? GetCurrentSemester().semester : semesterId);
    }
    setFilteredMode(false);
    setTeacherId(id);
    setTabValue(1);
    setCurrentPage(1);
    setHistoryPage(1);
  }

  const onProcessStudentListGroup = (id: number, semesterId: number) => {
    setCurrentOrg(0);
    setCurrentSubjectClass(0);
    teacherStore.getStudentListGroupByOrganisation(id, semesterId)
      .then((res) => {
        if (res.length > 0) {
          let defaultOrgId = res[0].id;
          setCurrentOrg(defaultOrgId);
          setCurrentSubjectClass(res.find(org => org.id == defaultOrgId).organisationStudentGroupByClass.length > 0 ?
            res.find(org => org.id == defaultOrgId).organisationStudentGroupByClass[0].subjectClassId : 0);
          // on process year semester label display
          if (calendarStore.calendarSettingList.data.length > 0) {
            let currentYear = calendarStore.calendarSettingList.data
              .find(y => y.yearDescription === GetCurrentSemester().startDate.split('-')[0]).yearDescription;
            setTimeKeyword(currentYear + " " +
              calendarStore.calendarSettingList.data.find(y => y.yearDescription === currentYear).schoolSemesters.find(s => s.id == +semesterId).semesterDescription);
          }
        }
      });
  }

  const onProcessCalendarSetting = (sourceData: any) => {
    if (sourceData.data.length > 0) {
      let currentYear = sourceData.data.find(y => y.yearDescription === GetCurrentSemester().startDate.split('-')[0]);
      setYearId(currentYear.id);
      setSemesterId(GetCurrentSemester().semester);
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    if (newValue == 0 && teacherStore.visitHistoryList.totalCount == 0) {
      // refresh visit list if history is never load
      teacherStore.getVisitListByStaffId(+teacherId, true, PAGE_SIZE, (historyPage - 1) * PAGE_SIZE);
    } else if (newValue == 1 && teacherStore.visitCurrentList.totalCount == 0) {
      teacherStore.getVisitListByStaffId(+teacherId, false, PAGE_SIZE, (currentPage - 1) * PAGE_SIZE);
    }
  };

  const handleChangeModalTab = (event: React.SyntheticEvent, newValue: number) => {
    setModalTabValue(newValue);
  }

  const handleShowModal = (id: number, isHistory: boolean, filtered?: boolean) => {
    let target: any;
    if (filtered) target = teacherStore.allVisitList.find(v => v.id == id);
    else {
      if (isHistory) target = teacherStore.visitHistoryList.data.find(v => v.id == id);
      else target = teacherStore.visitCurrentList.data.find(v => v.id == id);
    }
    if (target.organisationVisitStudents.length > 0)
      setStudentId(target.organisationStudentId);
    setCurrentVisit(target);
    setShowModal(true);
  }

  return (
    <ContentLayout
      pageName={t('TEACHER_PROFILE')}
      pageHeading={t('TEACHER_PROFILE')}
      breadCrumb={[
        { label: t('TEACHER_PROFILE') + t('SETTING_B'), to: "" },
        { label: t('OVERVIEW'), to: "/teacher" },
        { label: t('TEACHER_PROFILE'), to: "/teacher-profile" }
      ]}
      showHeadingButton={false}
    >
      <div className="mb-8" style={{ marginTop: -10 }}>
        <FormControl fullWidth sx={{ width: 300 }}>
          <InputLabel>{t('SELECT_TEACHER')}</InputLabel>
          <Select
            label={t('SELECT_TEACHER')}
            sx={{ width: 300, background: 'white' }}
            value={teacherId}
            onChange={onChangeTeacher}
          >
            <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
            {teacherStore.teacherProfileList.data.map((teacher: any, index: number) => (
              <MenuItem key={`teacher_select_${index}`} value={teacher.id}>
                {teacher.firstName + " " + teacher.lastName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {teacherStore.loading ?
        <>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
          <div className="text-center my-4"><Skeleton /></div>
        </>
        : teacherId === "0" || ApiDataResponseListLengthCheck(ApiDataListType.TEACHER) == 0 || !currentTeacher
          ? <div className="text-center"><Typography variant="h6">{t('NO_TEACHER_SELECTED')}</Typography></div>
          : <>
            <div className="grid grid-cols-4 gap-4 bg-white p-8 rounded-xl" style={{ marginTop: -10 }}>
              <TextField
                value={currentTeacher.title}
                label={t('TITLE')}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled
              />
              <TextField
                value={currentTeacher.username}
                label={t('USERNAME')}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled
              />
              <TextField
                value={currentTeacher.firstName}
                label={t('FIRST_NAME')}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled
              />
              <TextField
                value={currentTeacher.lastName}
                label={t('LAST_NAME')}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled
              />
              <TextField
                value={currentTeacher.middleName}
                label={t('MIDDLE_NAME')}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled
              />
              <TextField
                value={currentTeacher.birthDate.split('T')[0]}
                label={t('BOD')}
                type="date"
                variant="outlined"
                sx={{ width: '100%' }}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                value={currentTeacher.emailAddress}
                label={t('EMAIL')}
                variant="outlined"
                sx={{ width: '100%' }}
                className="col-span-2"
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </>}

      {currentTeacher && <>
        <Typography variant="h5" fontWeight={'bold'} marginBottom={2} marginTop={4}
          sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
          {t('STUDENT_LIST')}{currentTeacher && ": " + currentTeacher.firstName + " " + currentTeacher.lastName}
        </Typography>
        <div className="">
          <div className="grid grid-cols-5 gap-4 bg-white p-6 rounded-xl">
            <FormControl fullWidth className="col-span-2">
              <InputLabel>{t('YEAR')}</InputLabel>
              <Select
                label={t('YEAR')}
                sx={{ width: '100%' }}
                value={yearId}
                onChange={onChangeYear}
                size="small"
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {calendarStore.calendarSettingList.data.length > 0 &&
                  calendarStore.calendarSettingList.data.map((year: any, index: number) => (
                    <MenuItem key={`year_select_${index}`} value={year.id}>
                      {year.yearDescription}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl fullWidth className="col-span-2">
              <InputLabel>{t('SEMESTER')}</InputLabel>
              <Select
                label={t('SEMESTER')}
                sx={{ width: '100%' }}
                value={semesterId}
                disabled={yearId === "0"}
                onChange={onChangeSemester}
                size="small"
              >
                <MenuItem value={"0"}>{t('NOT_SELECTED')}</MenuItem>
                {yearId !== "0" &&
                  calendarStore.calendarSettingList.data.find(y => y.id == yearId).schoolSemesters
                    .map((semester: any, index: number) => (
                      <MenuItem key={`semester_select_${index}`} value={semester.id}>
                        {semester.semesterDescription}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              disabled={+semesterId == 0}
              onClick={() => {
                onProcessStudentListGroup(+teacherId, +semesterId);
                setTimeKeyword(calendarStore.calendarSettingList.data.find(y => y.id == +yearId).yearDescription + " " +
                  calendarStore.calendarSettingList.data.find(y => y.id == yearId).schoolSemesters.find(s => s.id == +semesterId).semesterDescription);
              }}
            >
              {t('APPLY')}
            </Button>
          </div>
          {+semesterId != 0 &&
            <>
              <div className="grid grid-cols-4 gap-4 mt-4">
                {teacherStore.loadingStudentList ? <div className="col-span-4 text-center"><CircularProgress className="text-theme" /></div>
                  : teacherStore.studentListByStaff.length > 0 ?
                    <>
                      <div className="max-h-96 overflow-auto p-2 bg-white rounded-xl">
                        <Typography marginBottom={1} fontWeight="bold">{t('ORGANISATION')}: {timeKeyword}</Typography>
                        {teacherStore.studentListByStaff.map((org: any, index: number) => (
                          <Box key={`student_org_select_${index}`}>
                            <button
                              type="button"
                              className={`${currentOrg == org.id ? INLINE_SELECTED_OPTION_MINI : INLINE_SELECT_OPTION_MINI}`}
                              onClick={() => setCurrentOrg(org.id)}
                            >
                              {org.name}
                            </button>
                          </Box>
                        ))}
                      </div>
                      <div className="max-h-96 overflow-auto p-2 bg-white rounded-xl">
                        <Typography marginBottom={1} fontWeight="bold">{t('SUBJECT_CLASS')}: {timeKeyword}</Typography>
                        {currentOrg != 0 ?
                          teacherStore.studentListByStaff.find(org => org.id == currentOrg)
                            .organisationStudentGroupByClass.map((sc: any, index: number) => (
                              <Box key={`student_subject_class_select_${index}`}>
                                <button
                                  type="button"
                                  className={`${currentSubjectClass == sc.subjectClassId ? INLINE_SELECTED_OPTION_MINI : INLINE_SELECT_OPTION_MINI}`}
                                  onClick={() => setCurrentSubjectClass(sc.subjectClassId)}
                                >
                                  {sc.classCode}
                                </button>
                              </Box>
                            )) : <Typography>{t('NO_ORGANISATION_SELECTED')}</Typography>}
                      </div>
                      <div className="max-h-96 overflow-auto col-span-2 p-2 bg-white rounded-xl">
                        <Typography marginBottom={1} fontWeight="bold">{t('STUDENTS')}: {timeKeyword}</Typography>
                        <div className="grid grid-cols-3 gap-4">
                          {(currentOrg != 0 && currentSubjectClass != 0) ?
                            teacherStore.studentListByStaff.find(org => org.id == currentOrg)
                              .organisationStudentGroupByClass.find(sc => sc.subjectClassId == currentSubjectClass)
                              .studentList.map((student: any, index: number) => (
                                <Box key={`student_select_${index}`} className="bg-theme text-white rounded-xl py-1 text-center">
                                  {/* <EmojiPeopleIcon /> */}
                                  <Typography variant="body2">{index + 1 + ". " + student.firstName + " " + student.lastName}</Typography>
                                </Box>
                              )) : <div className="col-span-3">
                              <Typography>{t('NO_ORGANISATION_SUBJECT_CLASS_SELECTED')}</Typography>
                            </div>}
                        </div>
                      </div>
                    </>
                    : <div className="col-span-5 text-center"><Typography>{t('NO_STUDENTS_FOUND')}</Typography></div>}
              </div>
              <div className="flex justify-end mt-4">
                <Button
                  variant="outlined"
                  disabled={currentOrg == 0 || currentSubjectClass == 0}
                  sx={{ backgroundColor: 'white' }}
                  onClick={() => {
                    teacherStore.getVisitListByOrgIdAndTrainerId(+teacherId, currentOrg, currentSubjectClass);
                    if (currentOrg != 0 && currentSubjectClass != 0) {
                      let tempInfo: any = {};
                      tempInfo.orgName = teacherStore.studentListByStaff.find(org => org.id == currentOrg).name;
                      tempInfo.classCode = teacherStore.studentListByStaff.find(org => org.id == currentOrg)
                        .organisationStudentGroupByClass.find(sc => sc.subjectClassId == currentSubjectClass).classCode;
                      setOrgSubjectClassInfo(tempInfo);
                    }
                    setFilteredMode(true);
                    setTabValue(2);
                    setSearchVisitName("");
                  }}
                >
                  {t('APPLY_KEYWORD_TO_VISIT_LIST')}
                </Button>
              </div>
            </>}
        </div>

        <div className="flex justify-between mt-4 mb-3">
          <Typography variant="h5" fontWeight={'bold'} marginTop={1}
            sx={{ borderLeftWidth: 8, borderColor: 'black', height: 'fit-content', paddingLeft: 2 }}>
            {t('VISIT_LIST')}{currentTeacher && ": " + currentTeacher.firstName + " " + currentTeacher.lastName}
          </Typography>
          <Tabs value={tabValue} onChange={handleChange}>
            <Tab label={t('HISTORY_VISIT')} {...TabProps(0)} />
            <Tab label={t('ACTIVE_VISIT')} {...TabProps(1)} />
            {filteredMode && <Tab label={t('VISIT_LIST_BASED_KEYWORDS')} {...TabProps(2)} />}
          </Tabs>
        </div>

        {/* History List */}
        <TabPanel value={tabValue} index={0}>
          <VmTable
            loading={teacherStore.loadingVisit}
            page={historyPage}
            paginationCount={teacherStore.visitHistoryList.totalCount == 0 ? 1 : teacherStore.visitHistoryList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setCurrentPage(pageNumber);
              teacherStore.getVisitListByStaffId(+teacherId, true, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('STATUS'), t('ACTION')]}
            tbody={teacherStore.visitHistoryList.data.length > 0 ?
              teacherStore.visitHistoryList.data.map((visit, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`visit_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{visit.name}</td>
                    <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>
                      <span className="bg-red-400 rounded-2xl text-white p-2 pt-1">
                        <Typography variant="caption">{t('CHECKED_OUT_BY_TEACHER')}</Typography>
                      </span>
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        onClick={() => handleShowModal(visit.id, true)}
                      >
                        {t('VIEW')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))
              : <tr><td className="text-center py-4" colSpan={6}>{t('NO_RESULT_FOUND')}</td></tr>}
          />
        </TabPanel>

        {/* Current List */}
        <TabPanel value={tabValue} index={1}>
          <VmTable
            loading={teacherStore.loadingVisit}
            page={currentPage}
            paginationCount={teacherStore.visitCurrentList.totalCount == 0 ? 1 : teacherStore.visitCurrentList.totalCount / PAGE_SIZE}
            onChangePagination={(event, pageNumber) => {
              setCurrentPage(pageNumber);
              teacherStore.getVisitListByStaffId(+teacherId, false, PAGE_SIZE, (pageNumber - 1) * PAGE_SIZE);
            }}
            thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), "File Upload Status", "Check in " + t('STATUS'), t('ACTION')]}
            tbody={teacherStore.visitCurrentList.data.length > 0 ?
              teacherStore.visitCurrentList.data.map((visit, index) => (
                <>
                  <Box marginY={1} />
                  <tr key={`visit_${index}`}>
                    <td className={TD_FIRST}>{index + 1}</td>
                    <td className={TD_NORMAL}>{visit.name}</td>
                    <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                    <td className={TD_NORMAL}>
                      {visit.organisationVisitDocuments.length > 0 ? 
                      <Box sx={ITEM_PERFECT_INLINED} className="text-green-500"><FileDownloadDoneIcon /><Typography marginLeft={1}>Files Uploaded</Typography></Box>
                    : <Box sx={ITEM_PERFECT_INLINED} className="text-red-500"><FileDownloadOffIcon /><Typography marginLeft={1}>No File Uploaded</Typography></Box>}
                    </td>
                    <td className={TD_NORMAL}>
                      <span className={`${visit.checkInTime ? "bg-green-400" : "bg-orange-400"} rounded-2xl text-white p-2 pt-1`}>
                        <Typography variant="caption">{visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                      </span>
                    </td>
                    <td className={TD_LAST}>
                      <Button
                        variant="outlined"
                        onClick={() => handleShowModal(visit.id, false)}
                      >
                        {t('VIEW')}
                      </Button>
                    </td>
                  </tr>
                  <Box marginY={1} />
                </>
              ))
              : <tr><td className="text-center py-4" colSpan={6}>{t('NO_RESULT_FOUND')}</td></tr>}
          />
        </TabPanel>

        {/* Filtered List */}
        <TabPanel value={tabValue} index={2}>
          <div className="flex mb-2 justify-between"
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              justifyContent: 'justify-between'
            }}>
            <div className="flex">
              <div className="bg-highlight rounded-2xl text-xs text-white p-2 mr-2">
                {t('ORGANISATION')}: {orgSubjectClassInfo.orgName}</div>
              <div className="bg-highlight rounded-2xl text-xs text-white p-2">
                {t('CLASS_CODE')}: {orgSubjectClassInfo.classCode}</div>
            </div>
            <TextField
              value={searchVisitName}
              onChange={(value) => setSearchVisitName(value.target.value)}
              label={t('SEARCH_VISIT_NAME')}
              size="small"
              sx={{ background: 'white' }}
            />
          </div>

          <VmTable
            loading={teacherStore.loadingVisit}
            page={0}
            thead={["ID", t('VISIT_NAME'), t('START_TIME'), t('END_TIME'), t('STATUS'), t('ACTION')]}
            tbody={teacherStore.allVisitList.length > 0 ?
              teacherStore.allVisitList.filter(v => searchVisitName === "" ? v.name !== "" : v.name.toLowerCase().includes(searchVisitName.toLowerCase()))
                .map((visit, index) => (
                  <>
                    <Box marginY={1} />
                    <tr key={`visit_${index}`}>
                      <td className={TD_FIRST}>{index + 1}</td>
                      <td className={TD_NORMAL}>{visit.name}</td>
                      <td className={TD_NORMAL}>{visit.startTime.split('T')[0] + " " + visit.startTime.split('T')[1]}</td>
                      <td className={TD_NORMAL}>{visit.endTime.split('T')[0] + " " + visit.endTime.split('T')[1]}</td>
                      <td className={TD_NORMAL}>
                        <span className={`${visit.checkInTime ? "bg-green-400" : "bg-orange-400"} rounded-2xl text-white p-2 pt-1`}>
                          <Typography variant="caption">{visit.checkInTime ? t('CHECKED_IN_BY_TEACHER') : t('NOT_CHECK_IN_BY_TEACHER')}</Typography>
                        </span>
                      </td>
                      <td className={TD_LAST}>
                        <Button
                          variant="outlined"
                          onClick={() => handleShowModal(visit.id, false, true)}
                        >
                          {t('VIEW')}
                        </Button>
                      </td>
                    </tr>
                    <Box marginY={1} />
                  </>
                ))
              : <tr><td className="text-center py-4" colSpan={6}>{t('NO_RESULT_FOUND')}</td></tr>}
          />
        </TabPanel>
      </>}


      <VmModal
        openModal={showModal}
        onClose={() => {
          setShowModal(false);
          setCurrentVisit(null);
          setModalTabValue(0);
        }}
        width={500}
        title={t('VISIT_INFO')}
        showButton={false}
      >
        {currentVisit &&
          <>
            <Box display="flex" justifyContent="center" width="100%" marginBottom={2}>
              <Tabs
                value={modalTabValue}
                onChange={handleChangeModalTab}
                sx={{ marginTop: -4, textAlign: 'center', justifyContent: 'center' }}
              // TabIndicatorProps={{ style: { background: THEME_COLOR } }}
              >
                <Tab label={t('VISIT_INFO')} {...TabProps(0)} />
                <Tab label={t('UPLOADED_FILES')} {...TabProps(1)} />
              </Tabs>
            </Box>
            <Divider sx={{ marginBottom: 1 }} />
            <TabPanel value={modalTabValue} index={0} paddingValue={0}>
              <table style={{ width: '100%' }}>
                <thead><th /><th /></thead>
                <tbody>
                  <tr>
                    <td className="font-bold">{t('ORGANISATION')}: </td>
                    <td className="text-right">{currentVisit.name}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('Location Name')}: </td>
                    <td className="text-right">{currentVisit.locationName ? currentVisit.locationName : "No Data"}</td>
                  </tr>                  <tr>
                    <td className="font-bold">{t('Student Name')}: </td>
                    <td className="text-right">{currentVisit.studentName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('CREATE_TIME')}: </td>
                    <td className="text-right">{currentVisit.createTime.split('T')[0] + " " + currentVisit.createTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('UPDATED_TIME')}: </td>
                    <td className="text-right">{currentVisit.updateTime.split('T')[0] + " " + currentVisit.updateTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('START_TIME')}: </td>
                    <td className="text-right">{currentVisit.startTime.split('T')[0] + " " + currentVisit.startTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('END_TIME')}: </td>
                    <td className="text-right">{currentVisit.endTime.split('T')[0] + " " + currentVisit.endTime.split('T')[1]}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Responsible Trainer Name: </td>
                    <td className="text-right">{currentVisit.responsibleTrainerName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Conduct Trainer Name: </td>
                    <td className="text-right">{currentVisit.conductTrainerName}</td>
                  </tr>
                  <tr>
                    <td className="font-bold">{t('DESCRIPTION')}: </td>
                  </tr>
                  <tr><td colSpan={2}>
                    <div className="my-2 mt-1">
                      <TextField
                        value={currentVisit.description}
                        rows={2}
                        sx={{ width: '100%' }}
                        disabled
                        multiline
                        size="small"
                      />
                    </div>
                  </td></tr>
                </tbody>
              </table>

              {studentId !== "0" &&
                <>
                  <Typography color={THEME_HIGHLIGHT} fontWeight="bold">
                    {`${t('STUDENT_SINGLE') + t('MARKS_B')}: ${currentVisit.organisationVisitStudents.result ?
                        currentVisit.organisationVisitStudents.result : 0}/100`}
                  </Typography>

                  <div className="my-2 mt-1">
                    <TextField
                      value={currentVisit.remark ?currentVisit.remark : t('NO_FEEDBACK_YET')}
                      rows={2}
                      sx={{ width: '100%' }}
                      disabled
                      multiline
                      size="small"
                    />
                  </div>
                </>}
            </TabPanel>
            <TabPanel value={modalTabValue} index={1} paddingValue={0}>
              {currentVisit.organisationVisitDocuments.length > 0 ?
                <div className="grid grid-cols-5 gap-4">
                  {currentVisit.organisationVisitDocuments.map((doc: any) => (
                    <button className="border hover:border-theme hover-border-2"
                      onClick={() => {
                        if (doc.documentName.split('.')[1] !== "pdf") {
                          window.open(constructImgUrl(currentVisit.id, doc.documentName));
                        } else {
                          groupStore.getUploadedFile(currentVisit.id, doc.documentName)
                            .then((res) => {
                              const file = new Blob([res], { type: "application/pdf" });
                              const fileURL = URL.createObjectURL(file);
                              window.open(fileURL);
                            })
                        }
                      }} disabled={groupStore.loadingFile}>
                      {doc.documentName.split('.')[1] !== "pdf" ?
                        <img src={constructImgUrl(currentVisit.id, doc.documentName)} />
                        : groupStore.loadingFile ? <CircularProgress className="text-theme" />
                          : <>
                            <FilePresentIcon fontSize={"large"} />
                            <Typography variant="caption">PDF</Typography>
                          </>}
                    </button>
                  ))}
                </div>
                : <Typography textAlign="center">{t('NO_DOCUMENT_UPLOADED_IN_THIS_VISIT')}</Typography>}
            </TabPanel>
          </>}
      </VmModal>
    </ContentLayout>
  )
});

export default TeacherProfilePage;
